import Vue from 'vue'
import App from './App.vue'
import router from './utils/router'

import axios from 'axios'
Vue.prototype.$http = axios
axios.defaults.baseURL = 'https://eo.api.uuunnniii.com/v4'

import i18n from "./locales"

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  render: h => h(App)
}).$mount('#app')