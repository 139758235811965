import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './en.json'
import zh_CN from './zh_CN.json'
import zh_HK from './zh_HK.json'

Vue.use(VueI18n)
export const i18n = new VueI18n({
  fallbackLocale: 'zh_CN',
  messages:{
    en: en,
    zh_CN: zh_CN,
    zh_HK: zh_HK
  }
})

const url_language = new URLSearchParams(window.location.search).get('language')
const user_language = url_language || navigator.language || navigator.userLanguage

if (user_language == "zh_HK" || user_language == "zh-HK") {
  i18n.locale = "zh_HK"
}else if (user_language.startsWith('zh')) {
  i18n.locale = "zh_CN"
} else {
  i18n.locale = "en"
}

export default i18n