<template>
  <div class="body">
    <div :class="block == 'right' ? 'container right-panel-active' : 'container'" id="container">

      <div class="form-container right-container">
        <transition name="fade-switch" mode="out-in">

          <div class="form" v-if="right_status == 'qrcode'" key="r-qrcode">
            <h1>{{ $t('login_with_qrcode_title') }}</h1>
            <img :src="'https://qr.tripleuni.com/img/?q=' + encodeURIComponent(qrcode_url)" class="qrcode">
            <div class="note">{{ $t('login_with_qrcode_note') }}</div>
            <div class="privacy">{{ $t('privacy_declare_1') }} <a :href="app_info.app_agreement_link" target="_blank">{{
      $t('privacy_declare_agreement') }}</a>{{ $t('privacy_declare_2') }}<a :href="app_info.app_privacy_link"
                target="_blank">{{ $t('privacy_declare_privacy') }}</a>{{ $t('privacy_declare_3') }}
            </div>
          </div>

          <div class="form" v-if="right_status == 'error'" key="r-error">
            <h1 style="margin-bottom: 15px">{{ $t('login_with_qrcode_error_title') }}</h1>
            <h2 style="margin-bottom: 15px">{{ right_error_msg }}</h2>
            <div class="button" style="margin-top: 20px;" @click="getQrCode">{{ $t('login_with_qrcode_error_button') }}
            </div>
          </div>

        </transition>
      </div>



      <div class="form-container left-container">

        <div class="banner">
          <h2>{{ $t('welcome_to') }}<br>{{ $t(app_id + '.app_name') }} !</h2>
        </div>

        <transition name="fade-switch" mode="out-in">

          <div class="form" v-if="left_status == 'email_entering_email'" key="email_entering_email">
            <h1>{{ $t('sign_in_title') }}</h1>
            <input type="email" :placeholder="$t('edu_email')" class="input" v-model="user_email"
              @keyup.enter="sendVcode" />
            <div :class="email_entering_email_error ? 'warning warning-active' : 'warning'">{{
      email_entering_email_error }}</div>
            <div class="button" style="margin-top: 10px;" @click="sendVcode">{{ $t('send_otp') }}</div>
            <div class="note" style="margin-top: 20px;">{{ $t('or_social') }}</div>
            <div class="social-container">
              <div class="social" @click="googleLogin"><img src="../assets/social-google.png"></img></div>
              <div class="social" @click="githubLogin"><img src="../assets/social-github.png"></img></div>
              <div class="social" @click="changeToOrg" v-if="app_info.app_support_org"><img
                  src="../assets/social-org.png"></img></div>
            </div>
            <div class="privacy">{{ $t('privacy_declare_1') }} <a :href="app_info.app_agreement_link" target="_blank">{{
      $t('privacy_declare_agreement') }}</a>{{ $t('privacy_declare_2') }}<a :href="app_info.app_privacy_link"
                target="_blank">{{ $t('privacy_declare_privacy') }}</a>{{ $t('privacy_declare_3') }}
            </div>
          </div>

          <div class="form" v-if="left_status == 'email_sending'" key="email_sending">
            <h2 style="margin-bottom: 15px">{{ $t('sending_otp') }}</h2>
            <div class="app-icon-container">
              <img :src="logo_src" class="app-icon" />
              <img :src="loading_dot_src" class="app-icon-loading" />
              <img src="../assets/logo-outlook.png" class="app-icon" />
            </div>
            <div style="height: 60px"></div>
          </div>

          <div class="form" v-if="left_status == 'email_entering_vcode'" key="email_entering_vcode">
            <h2 style="margin-bottom: 5px">{{ $t('sent_otp') }}</h2>
            <h2 style="margin-bottom: 10px">{{ user_email }}</h2>
            <input type="email" :placeholder="$t('otp')" class="input" v-model="vcode_vcode"
              @keyup.enter="verifyVcode" />
            <div :class="email_entering_vcode_error ? 'warning warning-active' : 'warning'">{{
      email_entering_vcode_error }}</div>
            <div class="button" style="margin-top: 10px;" @click="verifyVcode">{{ $t('submit') }}</div>
            <div class="privacy">{{ $t('privacy_declare_1') }} <a :href="app_info.app_agreement_link" target="_blank">{{
      $t('privacy_declare_agreement') }}</a>{{ $t('privacy_declare_2') }}<a :href="app_info.app_privacy_link"
                target="_blank">{{ $t('privacy_declare_privacy') }}</a>{{ $t('privacy_declare_3') }}
            </div>
          </div>

          <div class="form" v-if="left_status == 'email_verifying'" key="email_verifying">
            <h1 style="margin-bottom: 20px">{{ $t('verifying_otp') }}</h1>
            <div class="app-icon-container">
              <img :src="loading_ring_src" class="app-icon-loading-ring" />
            </div>
            <div style="height: 60px"></div>
          </div>

          <div class="form" v-if="left_status == 'choose_email'" key="choose_email">
            <h1 style="margin-bottom: 15px">{{ $t('choose_email_title') }}</h1>
            <div class="email-selection" @click="localLogin(data.access_token, data.user_email)"
              v-for="(data, email) in access_token_list">{{ email }}</div>
            <div class="button" style="margin-top: 20px;" @click="changeToEmail">{{ $t('choose_email_button') }}</div>
            <div class="privacy">{{ $t('privacy_declare_1') }} <a :href="app_info.app_agreement_link" target="_blank">{{
      $t('privacy_declare_agreement') }}</a>{{ $t('privacy_declare_2') }}<a :href="app_info.app_privacy_link"
                target="_blank">{{ $t('privacy_declare_privacy') }}</a>{{ $t('privacy_declare_3') }}
            </div>
          </div>

          <div class="form" v-if="left_status == 'choose_loading'" key="choose_loading">
            <h1 style="margin-bottom: 20px">{{ $t('signing_in') }}</h1>
            <div class="app-icon-container">
              <img :src="loading_ring_src" class="app-icon-loading-ring" />
            </div>
            <div style="height: 60px"></div>
          </div>

          <div class="form" v-if="left_status == 'org_entering'" key="org_entering">
            <h1>{{ $t('org_login_title') }}</h1>
            <input :placeholder="$t('org_account')" class="input" v-model="org_account" @keyup.enter="orgLogin" />
            <input type="password" :placeholder="$t('org_password')" class="input" v-model="org_password"
              style="margin-top: 10px;" @keyup.enter="orgLogin" />
            <div :class="org_entering_error ? 'warning warning-active' : 'warning'">{{ org_entering_error }}</div>
            <div class="button" style="margin-top: 10px;" @click="orgLogin">{{ $t('org_login_button') }}</div>
            <div class="privacy">{{ $t('privacy_declare_1') }} <a :href="app_info.app_agreement_link" target="_blank">{{
      $t('privacy_declare_agreement') }}</a>{{ $t('privacy_declare_2') }}<a :href="app_info.app_privacy_link"
                target="_blank">{{ $t('privacy_declare_privacy') }}</a>{{ $t('privacy_declare_3') }}
            </div>
          </div>

          <div class="form" v-if="left_status == 'org_loading'" key="org_loading">
            <h1 style="margin-bottom: 20px">{{ $t('signing_in') }}</h1>
            <div class="app-icon-container">
              <img :src="loading_ring_src" class="app-icon-loading-ring" />
            </div>
            <div style="height: 60px"></div>
          </div>

          <div class="form" v-if="left_status == 'google_loading'" key="google_loading">
            <h2 style="margin-bottom: 15px">{{ $t('signing_in_with_google') }}</h2>
            <div class="app-icon-container">
              <img src="../assets/social-google.png" class="app-icon" />
              <img :src="loading_dot_src" class="app-icon-loading" />
              <img :src="logo_src" class="app-icon" />
            </div>
            <div style="height: 60px"></div>
          </div>

          <div class="form" v-if="left_status == 'github_loading'" key="github_loading">
            <h2 style="margin-bottom: 15px">{{ $t('signing_in_with_github') }}</h2>
            <div class="app-icon-container">
              <img src="../assets/social-github.png" class="app-icon" />
              <img :src="loading_dot_src" class="app-icon-loading" />
              <img :src="logo_src" class="app-icon" />
            </div>
            <div style="height: 60px"></div>
          </div>

          <div class="form" v-if="left_status == 'error'" key="error">
            <h1 style="margin-bottom: 15px">{{ $t('error') }}</h1>
            <h2 style="margin-bottom: 15px">{{ error_msg }}</h2>
            <div class="button" style="margin-top: 20px;" @click="changeToEmail">{{ $t('choose_email_button') }}</div>
          </div>

        </transition>

        <div class="block"></div>
      </div>
      <div class="overlay-container">
        <div class="overlay">
          <div class="overlay-panel overlay-left">
            <h1>{{ $t('welcome_to') }}<br>{{ $t(app_id + '.app_name') }} !</h1>
            <p>{{ $t('login_with_qrcode_prompt') }}<br>{{ $t(app_id + '.app_slogan') }}</p>
            <div class="button button-overlay" id="signIn" @click="changeToLeft">{{ $t('login_with_email_button') }}
            </div>
          </div>
          <div class="overlay-panel overlay-right">
            <h1>{{ $t('welcome_to') }}<br>{{ $t(app_id + '.app_name') }} !</h1>
            <p>{{ $t('login_with_email_prompt') }}<br>{{ $t(app_id + '.app_slogan') }}</p>
            <div class="button button-overlay"
              :style="left_status == 'google_loading' || left_status == 'github_loading' || left_status == 'email_sending' || left_status == 'email_verifying' || left_status == 'choose_loading' || left_status == 'org_loading' ? 'opacity: 0; cursor: default;' : ''"
              id="signUp" @click="changeToRight">{{ $t('login_with_qrcode_button') }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from '@/utils/request'

export default {
  name: 'index',
  data() {
    return {
      left_status: 'email_entering_email',
      right_status: 'qrcode',
      block: 'left',
      domain: 'https://login.tripleuni.com',
      // domain: 'http://localhost:8081',
      callback: '',
      language: '',
      logo_src: null,
      app_id: null,
      app_info: null,
      error_msg: '',
      right_error_msg: '',
      access_token_list: {},
      school_list: {
        "HKU": ["@connect.hku.hk", "@hku.hk"],
        "CUHK": ["@link.cuhk.edu.hk", "@cuhk.edu.hk"],
        "UST": ["@connect.ust.hk", "@alumni.ust.hk", "@ust.hk"]
      },
      app_info_list: {
        "TripleUni": {
          "app_name": "Triple Uni",
          "app_slogan": "即刻进入港三专属的树洞平台",
          "app_support_school": ["HKU", "CUHK", "UST"],
          "app_support_org": true,
          "app_redirect_url": "https://tripleuni.com/login",
          "app_logo": "logo-UNI.png",
          "app_agreement_link": "https://terms.tripleuni.com/uni/agreement",
          "app_privacy_link": "https://terms.tripleuni.com/uni/privacy",
          "app_color": "#1F9BF0",
          "app_color_lighter": "#1FB1F0",
          "app_color_darker": "#1F80F0"
        },
        "TripleUniDev": {
          "app_name": "Triple Uni Dev",
          "app_slogan": "即刻进入港三专属的树洞平台",
          "app_support_school": ["HKU", "CUHK", "UST"],
          "app_support_org": true,
          "app_redirect_url": "https://dev.tripleuni.com/login",
          "app_logo": "logo-UNI.png",
          "app_agreement_link": "https://terms.tripleuni.com/uni/agreement",
          "app_privacy_link": "https://terms.tripleuni.com/uni/privacy",
          "app_color": "#1F9BF0",
          "app_color_lighter": "#1FB1F0",
          "app_color_darker": "#1F80F0"
        },
        "CUCampus": {
          "app_name": "CU Campus",
          "app_slogan": "即刻进入 CU 人专属的一站式校园信息平台",
          "app_support_school": ["CUHK"],
          "app_support_org": false,
          "app_redirect_url": "https://cucampus.one/login",
          "app_logo": "logo-CUCampus.png",
          "app_agreement_link": "https://terms.tripleuni.com/CUCampus/agreement",
          "app_privacy_link": "https://terms.tripleuni.com/CUCampus/privacy",
          "app_color": "#222D43",
          "app_color_lighter": "#303C58",
          "app_color_darker": "#1A2235"
        },
        "CUCampusDev": {
          "app_name": "CU Campus Dev",
          "app_slogan": "即刻进入 CU 人专属的一站式校园信息平台",
          "app_support_school": ["CUHK"],
          "app_support_org": false,
          "app_redirect_url": "https://cu-courses.vercel.app/login",
          "app_logo": "logo-CUCampus.png",
          "app_agreement_link": "https://terms.tripleuni.com/CUCampus/agreement",
          "app_privacy_link": "https://terms.tripleuni.com/CUCampus/privacy",
          "app_color": "#222D43",
          "app_color_lighter": "#303C58",
          "app_color_darker": "#1A2235"
        }
      },

      loading_dot_src: null,
      loading_ring_src: null,

      user_email: '',
      vcode_vcode: '',
      vcode_key: '',
      email_entering_email_error: '',
      email_entering_vcode_error: '',

      org_account: '',
      org_password: '',
      org_entering_error: '',

      qrcode_url: '',
      qrcode_key: '',
      is_checking_qrcode: false
    }
  },
  created() {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      this.loading_dot_src = require('../assets/loading-dot-dark.svg')
      this.loading_ring_src = require('../assets/loading-ring-dark.svg')
    } else {
      this.loading_dot_src = require('../assets/loading-dot-light.svg')
      this.loading_ring_src = require('../assets/loading-ring-light.svg')
    }
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 800) this.block = 'left'
    })

    this.getQrCode()

    this.app_id = this.$route.params.app_id
    this.callback = this.$route.query.callback
    this.language = this.$route.query.language
    this.access_token_list = JSON.parse(localStorage.getItem('access_token_list'))
    if (!this.access_token_list) this.access_token_list = {}
    if (!(this.app_id in this.app_info_list)) {
      window.location.href = '/TripleUni'
    }
    this.app_info = this.app_info_list[this.app_id]
    document.documentElement.style.setProperty('--app-color', this.app_info.app_color)
    document.documentElement.style.setProperty('--app-color-lighter', this.app_info.app_color_lighter)
    document.documentElement.style.setProperty('--app-color-darker', this.app_info.app_color_darker)
    document.title = this.$t('page_title') + this.$t(this.app_id + '.app_name')
    this.logo_src = require('../assets/' + this.app_info.app_logo)
    if (this.$route.params.social_name == 'Google') {
      this.left_status = 'google_loading'
      this.googleVerify()
    } else if (this.$route.params.social_name == 'GitHub') {
      this.left_status = 'github_loading'
      this.githubVerify()
    } else if (JSON.stringify(this.access_token_list) != '{}') {
      let access_token_count = 0
      let access_token_list = {}
      Object.keys(this.access_token_list).forEach((email) => {
        if (access_token_count >= 5) return
        if (this.app_info.app_support_school.includes(this.access_token_list[email].user_school_label) && (this.app_info.app_support_org || this.access_token_list[email].user_type == 'normal')) {
          access_token_list[email] = this.access_token_list[email]
          access_token_count += 1
        }
      })
      this.access_token_list = access_token_list
      if (access_token_count > 0) this.left_status = 'choose_email'
    }
  },
  methods: {
    changeToRight() {
      if (this.left_status == 'google_loading' || this.left_status == 'github_loading' || this.left_status == 'email_sending' || this.left_status == 'email_verifying' || this.left_status == 'choose_loading' || this.left_status == 'org_loading') {
        return
      }
      this.block = 'right'
      this.checkQrCode()
    },
    changeToLeft() {
      this.block = 'left'
    },
    changeToEmail() {
      this.left_status = 'email_entering_email'
    },
    changeToOrg() {
      this.left_status = 'org_entering'
    },
    sendVcode() {
      this.email_entering_email_error = ''

      const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      if (!pattern.test(this.user_email)) {
        setTimeout(() => {
          this.email_entering_email_error = this.$t('error_msg_6')
        }, 1)
        return
      }

      const email_parts = this.user_email.split('@')
      const user_email_suffix = '@' + email_parts[1]
      let user_school_label = null
      this.app_info.app_support_school.forEach((school_label) => {
        if (this.school_list[school_label].includes(user_email_suffix)) {
          user_school_label = school_label
        }
      })
      if (!user_school_label) {
        let email_entering_email_error = this.$t('error_msg_3_1')
        this.app_info.app_support_school.forEach((school_label) => {
          email_entering_email_error += school_label + ' '
        })
        email_entering_email_error += this.$t('error_msg_3_2')
        setTimeout(() => {
          this.email_entering_email_error = email_entering_email_error
        }, 1)
        return
      }

      this.user_email = this.user_email.toLowerCase()
      this.left_status = 'email_sending'

      request("/login/email", {
        user_email: this.user_email,
      })
        .then((res) => {
          if (!res || !res.code) {
            this.left_status = 'email_entering_email'
            setTimeout(() => {
              this.email_entering_email_error = this.$t('error_msg_1')
            }, 1)
            return
          } else if (res.code == 200) {
            this.left_status = 'email_entering_vcode'
            this.vcode_key = res.vcode_key
          } else if (res.code == 500 || res.code == 400 || res.code == 401) {
            this.left_status = 'email_entering_email'
            setTimeout(() => {
              this.email_entering_email_error = this.$t('error_msg_1')
            }, 1)
          } else if (res.code == 402) {
            this.left_status = 'email_entering_email'
            setTimeout(() => {
              this.email_entering_email_error = this.$t('error_msg_8')
            }, 1)
          } else if (res.code == 403) {
            this.left_status = 'email_entering_email'
            setTimeout(() => {
              this.email_entering_email_error = this.$t('error_msg_7')
            }, 1)
          } else {
            this.left_status = 'email_entering_email'
            setTimeout(() => {
              this.email_entering_email_error = this.$t('error_msg_1')
            }, 1)
          }
        })
    },
    verifyVcode() {
      this.email_entering_vcode_error = ''

      const pattern = /^\d{6}$/
      if (!pattern.test(this.vcode_vcode)) {
        setTimeout(() => {
          this.email_entering_vcode_error = this.$t('error_msg_9')
        }, 1)
        return
      }

      this.left_status = 'email_verifying'
      request("/login/verify", {
        user_email: this.user_email,
        vcode_vcode: this.vcode_vcode,
        vcode_key: this.vcode_key,
        app_id: this.app_id
      })
        .then((res) => {
          if (!res || !res.code) {
            this.left_status = 'email_verifying'
            setTimeout(() => {
              this.email_entering_vcode_error = this.$t('error_msg_5')
            }, 1)
            return
          } else if (res.code == 200) {
            let access_token_list = JSON.parse(localStorage.getItem('access_token_list'))
            if (!access_token_list) access_token_list = {}
            access_token_list[res.user_email] = {
              user_email: res.user_email,
              user_itsc: res.user_itsc,
              user_school_label: res.user_school_label,
              user_type: 'normal',
              access_token: res.access_token
            }
            this.access_token_list = access_token_list
            localStorage.setItem('access_token_list', JSON.stringify(access_token_list))

            let link = this.app_info.app_redirect_url + '?code=' + res.login_code
            if (this.callback) {
              link += '&callback=' + this.callback
            }
            if (this.language) {
              link += '&language=' + this.language
            }
            window.location.href = link
          }
          else if (res.code == 400) {
            this.left_status = 'email_entering_vcode'
            setTimeout(() => {
              this.email_entering_vcode_error = this.$t('error_msg_9')
            }, 1)
            return
          } else if (res.code == 500 || res.code == 501 || res.code == 502 || res.code == 503 || res.code == 701 || res.code == 702) {
            this.left_status = 'email_entering_vcode'
            setTimeout(() => {
              this.email_entering_vcode_error = this.$t('error_msg_1')
            }, 1)
            return
          } else {
            this.left_status = 'org_entering'
            setTimeout(() => {
              this.email_entering_vcode_error = this.$t('error_msg_1')
            }, 1)
          }
        })
    },
    localLogin(access_token, user_email) {
      this.left_status = 'choose_loading'
      request("/login/local", {
        access_token: access_token,
        user_email: user_email,
        app_id: this.app_id
      })
        .then((res) => {
          if (!res || !res.code) {
            this.left_status = 'error'
            this.error_msg = this.$t('error_msg_5')
            return
          } else if (res.code == 200) {
            let link = this.app_info.app_redirect_url + '?code=' + res.login_code
            if (this.callback) {
              link += '&callback=' + this.callback
            }
            if (this.language) {
              link += '&language=' + this.language
            }
            window.location.href = link
          }
          else if (res.code == 500 || res.code == 502 || res.code == 701) {
            let access_token_list = JSON.parse(localStorage.getItem('access_token_list'))
            if (!access_token_list) access_token_list = {}
            if (user_email in access_token_list) {
              delete access_token_list[user_email]
            }
            this.access_token_list = access_token_list
            localStorage.setItem('access_token_list', JSON.stringify(access_token_list))

            this.left_status = 'error'
            this.error_msg = this.$t('error_msg_4')
            return
          } else if (res.code == 501 || res.code == 702) {
            this.left_status = 'error'
            this.error_msg = this.$t('error_msg_1')
            return
          } else if (res.code == 703) {
            let error_msg = this.$t('error_msg_3_1')
            this.app_info.app_support_school.forEach((school_label) => {
              error_msg += school_label + ' '
            })
            error_msg += this.$t('error_msg_3_2')
            this.left_status = 'error'
            this.error_msg = error_msg
            return
          } else {
            this.left_status = 'error'
            this.error_msg = this.$t('error_msg_1')
          }
        })
    },
    orgLogin() {
      this.org_entering_error = ''
      if (!this.org_account) {
        setTimeout(() => {
          this.org_entering_error = this.$t('error_msg_10')
        }, 1)
        return
      }
      if (!this.org_password) {
        setTimeout(() => {
          this.org_entering_error = this.$t('error_msg_11')
        }, 1)
        return
      }
      this.left_status = 'org_loading'
      request("/login/org", {
        org_account: this.org_account,
        org_password: this.org_password,
        app_id: this.app_id
      })
        .then((res) => {
          if (!res || !res.code) {
            this.left_status = 'org_entering'
            setTimeout(() => {
              this.org_entering_error = this.$t('error_msg_5')
            }, 1)
            return
          } else if (res.code == 200) {
            let access_token_list = JSON.parse(localStorage.getItem('access_token_list'))
            if (!access_token_list) access_token_list = {}
            access_token_list[res.user_email] = {
              user_email: res.user_email,
              user_itsc: res.user_itsc,
              user_school_label: res.user_school_label,
              user_type: 'organization',
              access_token: res.access_token
            }
            this.access_token_list = access_token_list
            localStorage.setItem('access_token_list', JSON.stringify(access_token_list))

            let link = this.app_info.app_redirect_url + '?code=' + res.login_code
            if (this.callback) {
              link += '&callback=' + this.callback
            }
            if (this.language) {
              link += '&language=' + this.language
            }
            window.location.href = link
          }
          else if (res.code == 400) {
            this.left_status = 'org_entering'
            setTimeout(() => {
              this.org_entering_error = this.$t('error_msg_12')
            }, 1)
            return
          } else if (res.code == 500 || res.code == 501 || res.code == 502 || res.code == 701 || res.code == 702) {
            this.left_status = 'org_entering'
            setTimeout(() => {
              this.org_entering_error = this.$t('error_msg_1')
            }, 1)
            return
          } else if (res.code == 704) {
            this.left_status = 'error'
            this.error_msg = this.$t('error_msg_13')
            return
          } else {
            this.left_status = 'org_entering'
            setTimeout(() => {
              this.org_entering_error = this.$t('error_msg_1')
            }, 1)
          }
        })
    },
    googleLogin() {
      let link = 'https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A//www.googleapis.com/auth/userinfo.email&response_type=token&redirect_uri='
        + encodeURIComponent(this.domain + '/' + this.app_id + '/Google')
        + '&client_id=248939622807-atl617a297kj5g563tlubaqbpgvvs2vh.apps.googleusercontent.com'
      let state = {
        callback: this.callback,
        language: this.language
      }
      link += '&state=' + encodeURIComponent(JSON.stringify(state))
      window.location.href = link
    },
    googleVerify() {
      const params = new URLSearchParams(window.location.hash.substring(1))
      const code = params.get('access_token')
      const state = JSON.parse(params.get('state'))
      if (!code) {
        this.left_status = 'error'
        this.error_msg = this.$t('error_msg_14')
        return
      }
      request("/login/google", {
        code: code,
        app_id: this.app_id
      })
        .then((res) => {
          if (!res || !res.code) {
            this.left_status = 'error'
            this.error_msg = this.$t('error_msg_5')
            return
          } else if (res.code == 200) {
            let access_token_list = JSON.parse(localStorage.getItem('access_token_list'))
            if (!access_token_list) access_token_list = {}
            access_token_list[res.user_email] = {
              user_email: res.user_email,
              user_itsc: res.user_itsc,
              user_school_label: res.user_school_label,
              user_type: 'normal',
              access_token: res.access_token
            }
            this.access_token_list = access_token_list
            localStorage.setItem('access_token_list', JSON.stringify(access_token_list))

            let link = this.app_info.app_redirect_url + '?code=' + res.login_code
            if (state.callback) {
              link += '&callback=' + state.callback
            }
            if (state.language) {
              link += '&language=' + state.language
            }
            window.location.href = link
          }
          else if (res.code == 500 || res.code == 400) {
            this.left_status = 'error'
            this.error_msg = this.$t('error_msg_14')
            return
          } else if (res.code == 501 || res.code == 701 || res.code == 702) {
            this.left_status = 'error'
            this.error_msg = this.$t('error_msg_1')
            return
          } else if (res.code == 401 || res.code == 703) {
            let error_msg = this.$t('error_msg_3_1')
            this.app_info.app_support_school.forEach((school_label) => {
              error_msg += school_label + ' '
            })
            error_msg += this.$t('error_msg_3_2')
            this.left_status = 'error'
            this.error_msg = error_msg
            return
          } else {
            this.left_status = 'error'
            this.error_msg = this.$t('error_msg_1')
          }
        })

    },
    githubLogin() {
      let link = this.domain + '/' + this.app_id + '/GitHub'
      let first_param = true
      if (this.callback) {
        if (first_param) {
          link += '?callback=' + this.callback
          first_param = false
        } else link += '&callback=' + this.callback
      }
      if (this.language) {
        if (first_param) {
          link += '?language=' + this.language
          first_param = false
        } else link += '&language=' + this.language
      }

      window.location.href = 'https://github.com/login/oauth/authorize?client_id=d8efa05d0d3b8fb5878c&scope=user:email&redirect_uri='
        + encodeURIComponent(link)
    },
    githubVerify() {
      const code = this.$route.query.code
      if (!code) {
        this.left_status = 'error'
        this.error_msg = this.$t('error_msg_15')
        return
      }
      request("/login/github", {
        code: code,
        app_id: this.app_id
      })
        .then((res) => {
          if (!res || !res.code) {
            this.left_status = 'error'
            this.error_msg = this.$t('error_msg_5')
            return
          } else if (res.code == 200) {
            let access_token_list = JSON.parse(localStorage.getItem('access_token_list'))
            if (!access_token_list) access_token_list = {}
            access_token_list[res.user_email] = {
              user_email: res.user_email,
              user_itsc: res.user_itsc,
              user_school_label: res.user_school_label,
              user_type: 'normal',
              access_token: res.access_token
            }
            this.access_token_list = access_token_list
            localStorage.setItem('access_token_list', JSON.stringify(access_token_list))
            let link = this.app_info.app_redirect_url + '?code=' + res.login_code
            if (this.$route.query.callback) {
              link += '&callback=' + this.$route.query.callback
            }
            if (this.$route.query.language) {
              link += '&language=' + this.$route.query.language
            }
            window.location.href = link
          }
          else if (res.code == 500 || res.code == 400) {
            this.left_status = 'error'
            this.error_msg = this.$t('error_msg_15')
            return
          } else if (res.code == 501 || res.code == 701 || res.code == 702) {
            this.left_status = 'error'
            this.error_msg = this.$t('error_msg_1')
            return
          } else if (res.code == 401 || res.code == 703) {
            let error_msg = this.$t('error_msg_3_1')
            this.app_info.app_support_school.forEach((school_label) => {
              error_msg += school_label + ' '
            })
            error_msg += this.$t('error_msg_3_2')
            this.left_status = 'error'
            this.error_msg = error_msg
            return
          } else {
            this.left_status = 'error'
            this.error_msg = this.$t('error_msg_1')
          }
        })
    },
    getQrCode() {
      this.qrcode_key = ''
      this.qrcode_url = ''
      if (this.right_status != 'qrcode') this.right_status = 'qrcode'
      request("/login/getqrcode", {
      })
        .then((res) => {
          if (!res || !res.code) {
            this.right_status = 'error'
            this.right_error_msg = this.$t('error_msg_1')
            return
          } else if (res.code == 200) {
            if (this.right_status != 'qrcode') this.right_status = 'qrcode'
            this.qrcode_url = res.qrcode_url
            this.qrcode_key = res.qrcode_key
            setTimeout(() => {
              this.checkQrCode()
            }, 1000)
          } else {
            this.right_status = 'error'
            this.right_error_msg = this.$t('error_msg_1')
          }
        })
    },
    checkQrCode() {
      if (this.block != 'right') return
      if (this.is_checking_qrcode) return
      this.is_checking_qrcode = true
      request("/login/checkqrcode", {
        qrcode_key: this.qrcode_key,
        app_id: this.app_id
      })
        .then((res) => {
          this.is_checking_qrcode = false
          if (!res || !res.code) {
            this.right_status = 'error'
            this.right_error_msg = this.$t('error_msg_1')
            return
          } else if (res.code == 200) {
            let access_token_list = JSON.parse(localStorage.getItem('access_token_list'))
            if (!access_token_list) access_token_list = {}
            access_token_list[res.user_email] = {
              user_email: res.user_email,
              user_itsc: res.user_itsc,
              user_school_label: res.user_school_label,
              user_type: 'normal',
              access_token: res.access_token
            }
            this.access_token_list = access_token_list
            localStorage.setItem('access_token_list', JSON.stringify(access_token_list))

            let link = this.app_info.app_redirect_url + '?code=' + res.login_code
            if (this.callback) {
              link += '&callback=' + this.callback
            }
            if (this.language) {
              link += '&language=' + this.language
            }
            window.location.href = link
          } else if (res.code == 201) {
            setTimeout(() => {
              this.checkQrCode()
            }, 1000)
          } else if (res.code == 400 || res.code == 402 || res.code == 701 || res.code == 702) {
            this.right_status = 'error'
            this.right_error_msg = this.$t('error_msg_1')
            return
          } else if (res.code == 401) {
            this.right_status = 'error'
            this.right_error_msg = this.$t('error_msg_2')
            return
          } else if (res.code == 403 || res.code == 703) {
            let error_msg = this.$t('error_msg_3_1')
            this.app_info.app_support_school.forEach((school_label) => {
              error_msg += school_label + ' '
            })
            error_msg += this.$t('error_msg_3_2')
            this.right_status = 'error'
            this.right_error_msg = error_msg
            return
          } else {
            this.right_status = 'error'
            this.right_error_msg = this.$t('error_msg_1')
          }
        })
    }
  }
}
</script>

<style scoped>
.body {
  background: #F6F6F6;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  margin: 0;
  user-select: none;
}

h1 {
  font-weight: bold;
  margin: 0;
}

h2 {
  font-weight: bold;
  margin: 0;
}

p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}

.note {
  font-size: 14px;
}

.warning {
  margin-top: 5px;
  font-size: 12px;
  height: 15px;
  line-height: 15px;
  color: #DD0000;
  opacity: 0;
  display: inline-block;
}

.warning-active {
  opacity: 1;
  animation: shake 0.2s ease-out;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-5px);
  }

  50% {
    transform: translateX(5px);
  }

  75% {
    transform: translateX(-5px);
  }

  100% {
    transform: translateX(0);
  }
}

.privacy {
  font-size: 12px;
  margin-top: 16px;
}

.button {
  border-radius: 30px;
  border: 1px solid var(--app-color);
  background-color: var(--app-color);
  color: #FFFFFF;
  font-size: 13px;
  line-height: 16px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  transition: transform 80ms ease-in;
  cursor: pointer;
}

.button:active {
  transform: scale(0.95);
}

.button:focus {
  outline: none;
}

.button-overlay {
  background-color: transparent;
  border-color: #FFFFFF;
  transition: all 0.6s ease-in-out;
}

.form {
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  flex: 1;
  text-align: center;
}

.qrcode {
  width: 80%;
  aspect-ratio: 1;
  height: auto;
  object-fit: contain;
}

.input {
  background-color: #EEEEEE;
  color: #000000;
  border: none;
  padding: 10px;
  border-radius: 5px;
  gap: 8px;
  width: 90%;
  margin-top: 20px;
  font-size: 15px;
}

.container {
  background-color: #FFFFFF;
  border-radius: 20px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
    0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 800px;
  max-width: 100%;
  min-height: 480px;
}

.form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
  display: flex;
  flex-direction: column;
}

.left-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.container.right-panel-active .left-container {
  transform: translateX(100%);
}

.right-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
  background-color: #FFFFFF;
}

.container.right-panel-active .right-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}

@keyframes show {

  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }

}

.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.container.right-panel-active .overlay-container {
  transform: translateX(-100%);
}

.banner {
  display: none;
}

.overlay {
  background: var(--app-color);
  background: -webkit-linear-gradient(to right, var(--app-color-darker), var(--app-color-lighter));
  background: linear-gradient(to right, var(--app-color-darker), var(--app-color-lighter));
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #FFFFFF;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
  transform: translateX(50%);
}

.overlay-panel {
  box-sizing: border-box;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-left {
  transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
  transform: translateX(0);
}

.overlay-right {
  right: 0;
  transform: translateX(0);
}

.container.right-panel-active .overlay-right {
  transform: translateX(20%);
}

.social-container {
  margin-top: 20px;
}

.social {
  background-color: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
  cursor: pointer;
}

.social img {
  height: 40px;
  width: 40px;
  border-radius: 20px;
}

.email-selection {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  width: 80%;
  padding: 10px 30px;
  gap: 8px;
  background-color: #FFFFFF;
  border-radius: 30px;
  border: 2px solid #DDDDDD;
  transition: transform 150ms ease-in;
  cursor: pointer;
}

.email-selection:hover {
  transform: scale(1.02);
  border: 2px solid var(--app-color);
}


.block {
  display: none;
}

.fade-switch-enter-active {
  transition: opacity 0.25s ease-out;
}

.fade-switch-leave-active {
  transition: opacity 0.25s ease-in;
}

.fade-switch-enter,
.fade-switch-leave-to {
  opacity: 0;
}

.app-icon {
  width: 60px;
  height: 60px;
  border-radius: 15px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.15),
    0 10px 10px rgba(0, 0, 0, 0.12);
}

.app-icon-loading {
  width: 30px;
  height: 30px;
  margin: 0 30px;
}

.app-icon-loading-ring {
  width: 40px;
  height: 40px;
}

.app-icon-container {
  display: flex;
  margin-top: 40px;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 800px) {
  .left-container {
    width: 100%;
  }

  .right-container {
    width: 100%;
  }

  .overlay-container {
    display: none;
  }

  .container {
    /* width: 90%;
    min-height: 600px; */
    width: 100%;
    height: 100vh;
    border-radius: 0;
    box-shadow: none;
  }

  .form {
    padding: 20px;
  }

  .form-container {
    transition: none;
  }

  .banner {
    display: block;
    background: var(--app-color);
    background: -webkit-linear-gradient(to right, var(--app-color-darker), var(--app-color-lighter));
    background: linear-gradient(to right, var(--app-color-darker), var(--app-color-lighter));
    background-size: cover;
    background-position: 0 0;
    color: #FFFFFF;
    align-items: center;
    justify-content: center;
    padding: 15px;
  }

  .block {
    display: block;
    height: 100px;
  }
}

@media (prefers-color-scheme: dark) {
  .body {
    background: #000000;
    color: #FFFFFF;
  }

  .form {
    background-color: #181818;
  }

  .input {
    background-color: #333333;
    color: #FFFFFF;
  }

  .container {
    background-color: #181818;
    box-shadow: none;
  }

  .email-selection {
    background-color: #181818;
    border: 2px solid #333333;
  }

  .social {
    border: none;
  }

  .app-icon {
    background-color: #FFFFFF;
    box-shadow: none;
  }

  .qrcode {
    filter: invert(90.5882%);
  }

  .right-container {
    background-color: #181818;
  }
}
</style>