import Vue from 'vue'
import Router from 'vue-router'

import Index from '../components/Index.vue'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    { path: '/', redirect: '/TripleUni' },
    { path: '/:app_id/:social_name?', component: Index },
  ]
})

export default router