import axios from 'axios'
import qs from 'qs'
import { _ } from 'core-js'

export default async function request(url, body = {}){
    const url_language = new URLSearchParams(window.location.search).get('language')
    const user_language = url_language || navigator.language || navigator.userLanguage
    var language = "en"
    if (user_language.startsWith('zh')) {
        language = "zh-CN"
    }
    Object.assign(body, {language: language})

    try{
        const {data:res} = await axios.post(url + '.php', qs.stringify(body), {timeout: 15000})     
        return res
    }catch(e){
        return false
    }
    
}