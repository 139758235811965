<template>
  <div id="app" >
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'app',
  created() {
    
  },
  methods: {

  }
}
</script>

<style>
@import url("https://fonts.cdnfonts.com/css/chirp-2");


html {
  overflow-y: scroll;
  font-family: "Chirp", sans-serif;
  overscroll-behavior-y: contain;
  background-color: var(--bg);
}

:root {
  --main: #1f9bf0;
  --main-darker: #366ABD;
  --text: #000000;
  --bg: #ffffff;
  --hover-grey: #eeeeee;
  --border-grey: #f6f6f6;
  --shaddow-grey: #999999;
  --bg-grey: #dddddd;
  --text-grey: #8c8c8c;
  --vote-grey: #bbbbbb;
  --link: #0969da;
  --tip: linear-gradient(84.52deg, #FF5F5C 28.29%, #FE8A63 100%);
  --safe-bottom: env(safe-area-inset-bottom);
}

@media (prefers-color-scheme: dark) {
  :root {
    --main: #1f9bf0;
    --main-darker: #366ABD;
    --text: #ffffff;
    --bg: #000000;
    --hover-grey: #222222;
    --border-grey: #1A1A1A;
    --shaddow-grey: #777777;
    --bg-grey: #444444;
    --text-grey: #8c8c8c;
    --vote-grey: #bbbbbb;
    --link: #0969da;
    --tip: linear-gradient(84.52deg, #FF5F5C 28.29%, #FE8A63 100%);
    --safe-bottom: env(safe-area-inset-bottom);
  }
}
</style>